import parse from "html-react-parser";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { SystemMessageStyle } from "./MessageStyles.js";
import Button from "../../Button.js";
import BotFace from "../../icons/BotFace.js";

import {
  EVENT_REINIT_SILOS,
  EVENT_SILO_RESPONSE_B,
} from "../../../utils/controller.js";
import { addToQueue } from "../../../features/widgetStateSlice.js";
// ----------------------------------------------------------------------

const GroupSelectionMessageStyle = styled(SystemMessageStyle)`
  p {
    margin: 0 0 5px;
  }
`;

const ButtonWrapStyle = styled.div`
  margin: 10px 0;
  &:first-of-type {
    margin-top: 19px;
  }
  &:last-of-type {
    margin-bottom: 8px;
  }
  button {
    max-width: 100% !important;
  }
`;

export default function GroupSelectionMessage(props) {
  const { groups, isRelevant } = props;
  const dispatch = useDispatch();
  const themeContext = useTheme();

  return (
    <GroupSelectionMessageStyle>
      <BotFace />
      <div className="chat-bubble">
        {parse(themeContext.welcomeTextSilosIntro, {
          replace(domNode) {
            // console.log(domNode);
            if (domNode.attribs && domNode.attribs.class === "link_flowreset") {
              return (
                <a
                  disabled={!isRelevant}
                  style={{
                    pointerEvents: isRelevant ? "" : "none",
                    opacity: isRelevant ? "" : "0.6",
                  }}
                  onClick={() => {
                    if (isRelevant)
                      dispatch(addToQueue({ name: EVENT_REINIT_SILOS }));
                  }}
                >
                  start again
                </a>
              );
            }
          },
        })}
        {groups.map(([siloId, text]) => (
          <ButtonWrapStyle key={text}>
            <Button
              text={text}
              disabled={!isRelevant}
              onClick={() =>
                dispatch(
                  addToQueue({
                    name: EVENT_SILO_RESPONSE_B,
                    siloId: siloId,
                    siloText: text,
                  })
                )
              }
            />
          </ButtonWrapStyle>
        ))}
      </div>
    </GroupSelectionMessageStyle>
  );
}
