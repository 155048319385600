import parse from "html-react-parser";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useContext, useMemo } from "react";
import { SystemMessageStyle } from "./MessageStyles.js";
import ChatSpinner from "../ChatSpinner.js";
import Linkify from "linkify-react";
import BotFace from "../../icons/BotFace.js";

// ----------------------------------------------------------------------

const CompletionMessageStyle = styled(SystemMessageStyle)(
  ({ $isFinished }) => `
  // font-weight: ${$isFinished ? "400" : "600"};
  // color: ${!$isFinished ? "grey" : "black"};
`
);

const CompletionAnswerStyle = styled.div`
  border-left: 2px solid ${(props) => props.theme.systemMessageText};
  padding-left: 18px;
  margin-bottom: ${(props) => (props.$hasSource ? "17px" : "0px")};
  p {
    // border: 1px solid red;
    margin-block-end: 0.5em;
  }
`;

const CompletionSourceStyle = styled.div`
  word-break: break-word;
  span {
    display: inline-block;
    margin-right: 6px;
    font-weight: bold;
  }
`;
const agentsAvailable = false;
export default function CompletionMessage(props) {
  const { isFinished, noArticlesFound, answer, sourceQuestion } = props;
  const themeContext = useTheme();

  const hasSource = sourceQuestion;
  // sourceQuestion.length > 0

  const parsedAnswer = useMemo(() => parse(answer), [answer]);

  return (
    <CompletionMessageStyle $isFinished={isFinished}>
      <BotFace />
      <div className="chat-bubble">
        {isFinished ? (
          <>
            {sourceQuestion ? (
              <>
                {/* Found an answer */}
                <p>
                  Here is the best answer I can find based on your question:
                </p>
                <CompletionAnswerStyle $hasSource={hasSource}>
                  {/* <Linkify>{answer}</Linkify> */}
                  {parse(answer)}
                </CompletionAnswerStyle>
                {hasSource &&
                  Array.isArray(sourceQuestion) &&
                  sourceQuestion.map((sq) => (
                    <CompletionSourceStyle key={sq?.title}>
                      <span>Source:</span>
                      <a
                        target="_blank"
                        href={
                          sq.metadata?.article_path
                            ? `${sq.metadata?.article_path}`
                            : "#"
                        }
                      >
                        {sq?.title}
                      </a>
                    </CompletionSourceStyle>
                  ))}
                {hasSource && !Array.isArray(sourceQuestion) && (
                  <CompletionSourceStyle>
                    <span>Source:</span>
                    <a
                      target="_blank"
                      href={
                        hasSource
                          ? `${sourceQuestion.metadata?.article_path}`
                          : "#"
                      }
                    >
                      {sourceQuestion?.title}
                    </a>
                  </CompletionSourceStyle>
                )}
              </>
            ) : (
              <>
                Sorry I couldn't find find an answer to your question. You can
                try rephrasing it or{" "}
                <a href="{themeContext.contactLink}" target="_blank">
                  contact us
                </a>
                .
              </>
            )}
          </>
        ) : (
          <>
            {/* <p style={{ marginBottom: "5px" }}>
            I've found {noArticlesFound} articles that relate to your question.
          </p> */}
            <p>
              I'm analysing our knowledge base to see if there is an answer to
              your question…
            </p>
            <ChatSpinner inner={true} />
          </>
        )}
      </div>
    </CompletionMessageStyle>
  );
}
